



























import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import { Marked, MarkedOptions } from '@ts-stack/markdown';

@Component({})
export default class Markdown extends Vue {
    // Fields
    readonly cursor = '<span class="cursor" />';
    private _markedOptions: MarkedOptions;

    // Component Properties:
    @Prop({ required: true })
    public src!: string;

    @Prop({ required: false })
    public writing?: boolean;

    // Getter & Setter
    public get html(): string {
        return Marked.parse(`${this.src}${this.cursor}`, this._markedOptions);
    }

    // Component lifecycle methods:

    // Methods:
    constructor() {
        super();
        this._markedOptions = new MarkedOptions();
        this._markedOptions.gfm = true;
        this._markedOptions.tables = true;
        this._markedOptions.smartLists = true;
        this._markedOptions.isNoP = true;
        this._markedOptions.silent = true;
    }

    // Event Emitter:

}

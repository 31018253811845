















































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyObject, BusyList } from '@/models/Busy';
import { AssistantThreadsService, AssistantThread, AssistantThreadMessage, OpenAPI, AssistantThreadStreamingUpdate, RunCreationOptions } from '@/api/braendz'
import { ApiRequestOptions } from '@/api/braendz/core/ApiRequestOptions';
import { emitter } from "@/plugins/emitter";
import * as oboe from 'oboe';

import Markdown from "@/components/Markdown.vue";

// Types:
const assistantPostUserMessageEvent = "assistantPostUserMessageEvent";

// Global function to post a user message to an assistant.
export function postUserMessage(message: string, instruction?: string, instance?: string) {
    if(instance) {
        emitter.emit(`${assistantPostUserMessageEvent}-${instance}`, message, instruction);
    } else {
        emitter.emit(`${assistantPostUserMessageEvent}`, message, instruction);
    }
}

@Component({
  components: {
    Markdown
  }
})
export default class ChatSection extends Vue {   
    // Fields
    public userTextMessage = "";

    public assistantThread = new BusyObject<AssistantThread>();
    public assistantThreadMessages = new BusyList<BusyObject<AssistantThreadMessage>>();
    public newAssistantThreadMessage = new BusyObject<AssistantThreadMessage>();

    // Properties
    @Prop({required: false})
    public instance?: string;

    // Getter:
    
    // Watchers & Event Handlers:

    // Component Lifecycle
    public created(): void {
        this.$emitter.on(assistantPostUserMessageEvent, (message: string, instruction?: string) => {
            this.postMessage(message, instruction);
        });

        if(this.instance) {
            this.$emitter.on(`${assistantPostUserMessageEvent}-${this.instance}`, (message: string, instruction?: string) => {
                this.postMessage(message, instruction);
            });
        }
    }

    public beforeDestroy() {
        // E.g. Delete thread
    }

    // Methods
    public async postMessage(userMessage: string, instruction?: string): Promise<void> {
        await this.submitMessage(userMessage, instruction, false);
    }

    public async postUserTextMessage(): Promise<void> {
        if(!this.userTextMessage) {
            return;
        }

        await this.submitMessage(this.userTextMessage, undefined, true);
    }

    public async submitMessage(userMessage: string, instruction?: string, clearUserTextMessage?: boolean): Promise<void> {

        if(!this.assistantThread.object) {
            // Create a new chat.
            await this.assistantThread.create(async () => {
                return await AssistantThreadsService.createAssistantThread();
            });
        }

        await this.newAssistantThreadMessage.create(async () => {
            if(this.assistantThread.object?.id) {
                return await AssistantThreadsService.addAssistantThreadMessage(this.assistantThread.object.id, { content: [{ text: userMessage }] });
            }
            return null;
        });

        if(this.newAssistantThreadMessage.object && this.assistantThread.object?.id) {

            this.assistantThreadMessages.list.push(new BusyObject(this.newAssistantThreadMessage.object));

            if(clearUserTextMessage) {
                this.userTextMessage = "";
            }

            // Getting the thread stream:
            var requestConfig = {
                url: `${OpenAPI.BASE}/api/v1/AssistantThreads/${this.assistantThread.object.id}`,
                method: "PATCH",
                cached: false,
                headers: {
                    Authorization: (OpenAPI.TOKEN && typeof OpenAPI.TOKEN === 'function') ? `Bearer ${await OpenAPI.TOKEN({} as ApiRequestOptions)}` : OpenAPI.TOKEN
                },
                body: {
                    additionalInstructions: `Respond in the language and cultural context of '${this.$i18n.locale}' and format your response using Markdown.`
                } as RunCreationOptions
            }
            const executionStreamService = oboe(requestConfig);
            executionStreamService.node('!.*', (streamingUpdate: AssistantThreadStreamingUpdate) => {

                // Case: The message was updated:
                if(streamingUpdate.messageStatusUpdate) {
                    const message = this.assistantThreadMessages.list.find(i => i.object?.id === streamingUpdate.messageStatusUpdate?.id);

                    if(!message) {
                        // The message needs to be added to the list:
                        this.assistantThreadMessages.list.push(new BusyObject(streamingUpdate.messageStatusUpdate));
                    }
                    else {
                        // An existing message was updated
                        message.object = streamingUpdate.messageStatusUpdate;
                    }
                }

                // Case: The content stream was updated - e.g. the message text needs to be added:
                if(streamingUpdate.messageContentUpdate) {
                    
                    if(streamingUpdate.messageContentUpdate.textFragment) {
                        // Find the corresponding message:
                        const message = this.assistantThreadMessages.list.find(i => i.object?.id === streamingUpdate.messageContentUpdate?.messageId)?.object;
                        if(message) {
                            if(!message.content 
                                || message.content.length === 0 
                                || !message.content[message.content.length -1]
                                || !message.content[message.content.length -1].text) {

                                // Initialize the content:
                                message.content = [{text: ""}];
                            }

                            var lastContent = message.content[message.content.length -1];
                            // Append the text to the message:
                            lastContent.text += streamingUpdate.messageContentUpdate.textFragment;
                        }
                    }
                }

                // Case: The tread was updated
                if(streamingUpdate.threadUpdate) {
                    if(this.assistantThread.object?.id === streamingUpdate.threadUpdate.id) {
                        this.assistantThread.object = streamingUpdate.threadUpdate;
                    }
                }

                // Add more cases here...


                // Scroll to bottom:
                if(this.$refs.endOfMessages && this.$refs.messagesSection) {
                    this.$vuetify.goTo(this.$refs.endOfMessages as HTMLElement, { 
                        container: this.$refs.messagesSection as HTMLElement,
                        easing: 'linear'
                    });
                }
            });
        }
    }
}
